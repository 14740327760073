import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'en',
  messages: {
    fr: {
      importsExports: {
        model: {
          customer: 'Clients',
          prospect: 'Prospects',
          provider: 'Fournisseurs',
          subcontractor: 'Sous-traitants',
          contact: 'Contacts',
          contactFunction: 'Fonctions des contacts',
          quotation: 'Devis',
          'quotation-full': 'Contenu des devis',
          delivery: 'Bons de livraison',
          'delivery-full': 'Contenu des bons de livraison',
          command: 'Bons de commande',
          'command-full': 'Contenu des bons de commande',
          invoice: 'Factures',
          'invoice-full': 'Contenu des factures',
          credit: 'Avoirs',
          'credit-full': 'Contenu des avoirs',
          proforma: 'Facture proforma',
          'proforma-full': 'Contenu des facture proforma',
          payment: 'Encaissements',
          'charge-payment': 'Paiements',
          charge: 'Charges',
          'charge-full': 'Contenu des charges',
          contract: 'Contrats',
          'contract-deadline': 'Échéances des contrats',
          task: 'Tâches',
          product: 'Produits',
          service: 'Prestations',
          providerReference: 'Produits - Références fournisseur',
          productVariations: 'Variations de produits',
          opportunity: 'Opportunités'
        }
      }
    }
  }
})

export default i18n
