import VueI18n from 'vue-i18n'
import { uppercaseFirst, termText } from '@kwixeo/constructions-vue'
import Vue from 'vue'

const addIcon = ['fas', 'plus-circle']

const menu: (i18n: VueI18n) => KwixeoInterface.MainMenuEntries = i18n => {
  const config = Vue.prototype.$kwConstructions.store.getters['user/teamConfigs']
  return [
    {
      label: 'Tableau de bord',
      path: '/tbord'
    },
    {
      label: i18n.t('customers.menuTitle'),
      icon: ['fas', 'user-friends'],
      open: false,
      active: '/contacts',
      items: [
        {
          label: i18n.t('customers.prospect.menu'),
          path: '/contacts/prospect',
          appModule: 'customers-prospects',
          userPermission: ['prospect', 1],
          addIcon: addIcon,
          addTitle: i18n.t('customers.prospect.addLabel'),
          addModal: 'prospect-form',
          addUserPermission: ['prospect', 2]
        },
        {
          label: i18n.t('customers.customer.menu'),
          path: '/contacts/customer',
          userPermission: ['customer', 1],
          addIcon: addIcon,
          addTitle: i18n.t('customers.customer.addLabel'),
          addModal: 'customer-form',
          addUserPermission: ['customer', 2]
        },
        {
          label: i18n.t('customers.contact.menu'),
          path: '/contacts/contacts',
          userPermission: ['contact', 1],
          addIcon: addIcon,
          addTitle: i18n.t('customers.contact.addLabel'),
          addModal: 'contact-form',
          addUserPermission: ['contact', 2]
        },
        {
          label: i18n.t('customers.provider.menu'),
          path: '/contacts/provider',
          userPermission: ['provider', 1],
          addIcon: addIcon,
          addTitle: i18n.t('customers.provider.addLabel'),
          addModal: 'provider-form',
          addUserPermission: ['provider', 2]
        },
        {
          label: i18n.t('customers.subcontractor.menu'),
          path: '/contacts/subcontractor',
          userPermission: ['subcontractor', 1],
          addIcon: addIcon,
          addTitle: i18n.t('customers.subcontractor.addLabel'),
          addModal: 'subcontractor-form',
          addUserPermission: ['subcontractor', 2],
          appModule: 'customers-subcontractors'
        },
        {
          label: 'Statistiques',
          path: '/contacts/statistics',
          userPermission: ['sales-invoice', 1]
        }
      ]
    },
    {
      label: 'Véhicules',
      icon: ['fas', 'cars'],
      active: '/cars',
      path: '/cars',
      appModule: 'automotive'
    },
    {
      label: i18n.t('opportunities.opportunities.menu'),
      icon: ['fas', 'folders'],
      active: '/opportunities',
      appModule: 'opportunities',
      userPermission: ['opportunities', 1],
      open: false,
      items: [
        {
          label: i18n.t('opportunities.funnels.menu'),
          path: '/opportunities/funnels',
        },
        {
          label: i18n.t('opportunities.opportunities.menu'),
          path: '/opportunities/opportunities',
        }
      ]
    },
    {
      label: 'Ventes',
      icon: ['fas', 'file-alt'],
      open: false,
      active: '/sales',
      items: [
        {
          label: 'Devis',
          path: '/sales/quotations',
          userPermission: ['sales-quotation', 1],
          addIcon: addIcon,
          addRoute: '/sales/quotations/form',
          addUserPermission: ['sales-quotation', 2]
        },
        {
          label: 'Bons de commande',
          path: '/sales/commands',
          appModule: 'sales-commands',
          userPermission: ['sales-command', 1],
          addIcon: addIcon,
          addRoute: '/sales/commands/form',
          addUserPermission: ['sales-command', 2]
        },
        {
          label: 'Bons de livraison',
          path: '/sales/deliveries',
          appModule: 'sales-deliveries',
          userPermission: ['sales-delivery', 1],
          addIcon: addIcon,
          addRoute: '/sales/deliveries/form',
          addUserPermission: ['sales-delivery', 2]
        },
        {
          label: 'Factures proforma',
          path: '/sales/proformas',
          appModule: 'sales-proformas',
          userPermission: ['sales-proforma', 1],
          addIcon: addIcon,
          addRoute: '/sales/proformas/form',
          addUserPermission: ['sales-proforma', 2]
        }
      ]
    },
    {
      label: 'Facturation',
      icon: ['fas', 'file-invoice'],
      open: false,
      active: '/invoicing',
      items: [
        {
          label: 'Factures',
          path: '/invoicing/invoices',
          userPermission: ['sales-invoice', 1],
          addIcon: addIcon,
          addRoute: '/invoicing/invoices/form',
          addUserPermission: ['sales-invoice', 2]
        },
        {
          label: 'Factures d\'acompte',
          path: '/invoicing/deposit-invoices',
          userPermission: ['sales-invoice', 1]
        },
        {
          label: 'Avoirs',
          path: '/invoicing/credits',
          userPermission: ['sales-credit', 1],
          addIcon: addIcon,
          addRoute: '/invoicing/credits/form',
          addUserPermission: ['sales-credit', 2]
        },
        {
          label: 'Retenues de garantie',
          path: '/invoicing/guarantee-holdback',
          appModule: 'sales-guarantee-holdback',
          userPermission: ['sales-invoice', 1]
        },
        {
          label: 'Factures impayées',
          path: '/invoicing/to-paid-invoices',
          userPermission: ['sales-invoice', 1]
        },
        {
          label: 'Relance des impayées',
          path: '/invoicing/recovery',
          appModule: 'sales-recovery',
          userPermission: ['sales-invoice', 1]
        },
        {
          label: 'Récapitulatif de la TVA',
          path: '/invoicing/vatrates',
          userPermission: ['sales-invoice', 1]
        }
      ]
    },
    {
      label: 'Contrats',
      icon: ['fas', 'file-contract'],
      open: false,
      active: '/contracts',
      userPermission: ['sales-contracts', 1],
      appModule: 'sales-contracts',
      items: [
        {
          label: 'Contrats',
          path: '/contracts/contracts',
          appModule: 'sales-contracts',
          userPermission: ['sales-contracts', 1],
          addIcon: addIcon,
          addRoute: '/contracts/contracts/form',
          addUserPermission: ['sales-contracts', 2]
        },
        {
          label: 'Échéances',
          path: '/contracts/deadlines',
          appModule: 'sales-contracts',
          userPermission: ['sales-contracts', 1]
        }
      ]
    },
    {
      label: 'Encaissements',
      icon: ['fas', 'credit-card'],
      open: false,
      active: '/payments',
      userPermission: ['sales-invoice', 1],
      items: [
        {
          label: 'Encaissements',
          path: '/payments/payments'
        },
        {
          label: 'Récapitulatif de la TVA',
          path: '/payments/vatrates'
        },
        {
          label: 'Remise de chèque',
          path: '/payments/check-deposit',
          addIcon: addIcon,
          addRoute: '/payments/check-deposit/form',
          appModule: 'sales-checks-deposits'
        },
        {
          label: 'Prélèvements SEPA',
          path: '/payments/deposit-sepa',
          addIcon: addIcon,
          addRoute: '/payments/deposit-sepa/form',
          appModule: 'sales-sepa'
        },
        {
          label: 'Mandats SEPA',
          path: '/payments/sepa',
          appModule: 'sales-sepa'
        }
      ]
    },
    {
      label: uppercaseFirst(config.construction_customize ? config.construction_customize_menu_label : null) ?? i18n.t('constructions.menuTitle'),
      icon: ['fas', (config.construction_customize ? config.construction_customize_menu_icon : null) ?? 'traffic-cone'],
      open: false,
      active: '/constructions',
      appModule: 'construction',
      items: [
        {
          label: uppercaseFirst(termText('construction.menu', 'chantier', true)) as string,
          path: '/constructions/constructions',
          addIcon: addIcon,
          addRoute: { name: 'construction-form' },
          addTitle: uppercaseFirst(termText('construction.menuAddTitle', 'chantier')) as string,
        },
        {
          label: uppercaseFirst(termText('construction.menu', 'planning', true)) as string,
          path: '/constructions/planifications'
        },
        {
          label: uppercaseFirst(termText('construction.planning.menu', 'chantier', true)) as string,
          path: '/constructions/calendar'
        },
        {
          label: uppercaseFirst(termText('construction.planning.menu', 'technician', true)) as string,
          path: '/constructions/technicians/calendar'
        }
      ]
    },
    {
      label: i18n.t('tickets.menuLabel'),
      path: '/tickets',
      icon: ['fas', 'comments-alt'],
      appModule: 'tickets',
      userPermission: ['tickets', 1]
    },
    {
      label: 'Commissions',
      icon: ['fas', 'badge-percent'],
      open: false,
      active: '/commissions',
      appModule: 'sales-commissions',
      userPermission: ['sales-commissions', 1],
      items: [
        {
          label: 'Commissions',
          path: '/commissions/commissions'
        },
        {
          label: 'Paiements',
          path: '/commissions/payments'
        }
      ]
    },
    {
      label: i18n.t('catalog.menuTitle'),
      icon: ['fas', 'books'],
      open: false,
      active: '/catalog',
      items: [
        {
          label: i18n.t('catalog.product.menu'),
          path: '/catalog/products',
          userPermission: ['catalog-products', 1],
          addIcon: addIcon,
          addModal: 'catalog-product-form',
          addUserPermission: ['catalog-products', 2]
        },
        {
          label: i18n.t('catalog.service.menu'),
          path: '/catalog/services',
          userPermission: ['catalog-services', 1],
          addIcon: addIcon,
          addModal: 'catalog-service-form',
          addUserPermission: ['catalog-services', 2]
        },
        {
          label: i18n.t('catalog.group.menu'),
          path: '/catalog/groups',
          userPermission: ['catalog-groups', 1],
          addIcon: addIcon,
          addModal: 'catalog-group-form',
          appModule: 'catalog-groups',
          addUserPermission: ['catalog-groups', 2]
        },
        {
          label: 'Statistiques',
          path: '/catalog/report',
          userPermission: ['sales-invoice', 1]
        }
      ]
    },
    {
      label: i18n.t('catalog.stock.menuTitle'),
      icon: ['fas', 'box-open'],
      open: false,
      active: '/stock',
      appModule: 'stock',
      userPermission: ['see-stock', 1],
      items: [
        {
          label: 'État',
          path: '/stock/state',
          userPermission: ['see-stock', 1]
        },
        {
          label: 'Mouvements',
          path: '/stock/mouvements',
          userPermission: ['see-stock', 1]
        },
        {
          label: 'Lots de produits',
          path: '/stock/lots',
          appModule: 'stock-tracabilities',
          userPermission: ['see-stock', 1]
        },
        {
          label: 'Commandes',
          path: '/stock/commands',
          userPermission: ['see-stock', 1],
          addIcon: addIcon,
          addRoute: '/stock/commands/form',
          addUserPermission: ['manage-stock', 1]
        },
        {
          label: 'Entrées & Sorties',
          path: '/stock/manual-mouvements',
          userPermission: ['see-stock', 1],
          addIcon: addIcon,
          addModal: 'stock-manual-form',
          addUserPermission: ['manage-stock', 1]
        }
      ]
    },
    {
      label: i18n.t('charges.menuTitle'),
      icon: ['fas', 'shopping-cart'],
      open: false,
      active: '/charges',
      appModule: 'charges',
      userPermission: ['charges', 1],
      items: [
        {
          label: i18n.t('charges.charges.menu'),
          path: '/charges/charges',
          userPermission: ['charges', 1],
          addIcon: addIcon,
          addRoute: { name: 'charges-form' },
          addUserPermission: ['charges', 2]
        },
        {
          label: i18n.t('charges.payments.menu'),
          path: '/charges/payments',
          userPermission: ['charges', 1]
        }
      ]
    },
    {
      label: 'Experts-comptable',
      onlyAccountant: true,
      path: '/accountant/team'
    },
    {
      label: 'Marque blanche',
      active: '/admin',
      rootOrAdmin: true,
      open: false,
      items: [
        {
          label: 'Mon application',
          path: '/admin/my-app',
          rootOrAdmin: true
        },
        {
          label: 'Comptes',
          path: '/admin/team',
          rootOrAdmin: true
        },
        {
          label: 'Commandes',
          path: '/admin/command-list',
          rootOrAdmin: true
        },
        {
          label: 'Apps',
          path: '/admin/app',
          onlyRoot: true
        },
        {
          label: 'Experts-comptable',
          path: '/admin/accountant',
          onlyRoot: true
        },
        {
          label: 'Liste des aides',
          path: '/admin/help'
        },
        {
          label: 'Commissions',
          path: '/admin/sponsorship',
          onlyRoot: true
        }
      ]
    }
  ]
}

export default menu
